import React from 'react'
import { Link } from 'gatsby'

export default function FourOFour() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', padding: '32px'}}>
            <h1 style={{textAlign: 'center'}}>404</h1>
            <Link style={{textAlign: 'center'}} to='/'>Ana Sayfaya Dön</Link>
        </div>
    )
}